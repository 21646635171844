import styled from "styled-components";
import { useState, useEffect } from "react";
import hand from "../images/hand.svg";
import medal from "../images/medal.svg";
import star from "../images/star.svg";
import ing from "../images/ing.svg";
import downloadExcel from "../images/downloadExcel.svg";
import axios from "axios";
import Pusher from "pusher-js";

const Wrapper = styled.div`
  font-family: Roboto;
  width: 450px;
  background-color: #fff;
  text-align: center;
`;
const DateDiv = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #969696;
  margin-top: 82px;
`;
const TitleDiv = styled.div`
  margin-top: 5px;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #313131;
`;
const PosterImg = styled.img`
  margin-top: 40px;
  width: 375px;
`;
const PagniationImg = styled.img`
  margin-top: 60px;
  width: 218px;
`;
const LeftBar2 = () => {
  return (
    <Wrapper>
      <DateDiv>2023.01.13</DateDiv>
      <TitleDiv>진행중인 공연 및 행사</TitleDiv>
      <PosterImg
        src={"/leftbar2.png"}
        onClick={() => {
          window.location.href = "/";
        }}
      />
      <PagniationImg src={"leftbarpaginiation.png"} />
    </Wrapper>
  );
};

export default LeftBar2;
