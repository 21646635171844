import Box from "../../component/Box";
import ApexCharts from "react-apexcharts";

const DoubleLineChart = () => {
  const series = [
    {
      name: "공연",
      data: [78, 94, 76, 80, 91, 58],
    },
    {
      name: "행사",
      data: [43, 54, 90, 100, 65, 74],
    },
  ];
  return (
    <Box>
      <ApexCharts
        width={338}
        height={316}
        type="line"
        series={series}
        options={{
          chart: {
            zoom: {
              enabled: false,
            },
          },
          xaxis: {
            categories: ["Aug", "Sep", "Oct", "Nov", "Dec", "Jan"],
            labels: {
              style: {
                fontSize: "15px",
                colors: [
                  "#969696",
                  "#969696",
                  "#969696",
                  "#969696",
                  "#969696",
                  "#969696",
                ],
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          yaxis: {
            min: 0,
            max: 100,
            tickAmount: 5,
            labels: {
              formatter(val, opts) {
                if (val === 0) {
                  return "";
                } else {
                  return val;
                }
              },
              style: {
                fontSize: "15px",
                fontWeight: 400,
                colors: ["#969696"],
              },
            },
          },
          legend: {
            position: "top",
          },
          stroke: {
            curve: "straight",
            width: [2, 2],
            colors: ["#1C6ECD", "#0A929B"],
          },
          tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              console.log(w);
              return (
                '<div class="box">' +
                '<span class = "goods">' +
                w.globals.seriesNames[seriesIndex] +
                "횟수</span>" +
                '<span class="scores">' +
                series[seriesIndex][dataPointIndex] +
                "회" +
                "</span>" +
                "</div>"
              );
            },
          },
        }}
      />
    </Box>
  );
};
export default DoubleLineChart;
