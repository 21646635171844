import Container from "../components/Container";
import GradientLineChart from "../components/LineChart";
import styled from "styled-components";
import LeftBar from "../components/LeftBar";
import AgeChart from "../components/AgeChart";
import BarChart from "../components/BarChart";
import CircleChart from "../components/CircleChart";
import TopBar from "../components/TopBar";
import House from "../images/house.svg";
const Layout = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;
const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const ChartInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 1410px;
`;
const HomeBar = styled.div`
  background-color: #0a929b;
  height: 1080px;
  width: 60px;
  display: flex;
  flex-direction: column;
  padding-top: 48px;
  align-items: center;
`;
const ImgContainer = styled.img`
  width: 24px;
  height: 24px;
`;
const Main = () => {
  return (
    <Layout>
      <HomeBar>
        <ImgContainer src={House} />
      </HomeBar>
      <LeftBar />
      <ChartContainer>
        <ChartInput>
          <TopBar />
        </ChartInput>
        <ChartInput>
          <Container
            width={890}
            height={430}
            chart={<GradientLineChart />}
            title={"방문객 수 현황"}
          />
          <Container
            width={410}
            height={437}
            chart={<CircleChart />}
            title={"방문객 성별"}
            date={"2023.01"}
          />
        </ChartInput>
        <ChartInput>
          <Container
            width={890}
            height={437}
            chart={<BarChart />}
            title={"방문객 평균 만족도"}
          />
          <Container
            width={410}
            height={437}
            chart={<AgeChart />}
            title={"방문객 연령층"}
            date={"2023.01"}
          />
        </ChartInput>
      </ChartContainer>
    </Layout>
  );
};
export default Main;
