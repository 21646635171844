import Box from "../../component/Box";
import ApexCharts from "react-apexcharts";
import styled from "styled-components";
import "./styled.css";

const MyBarChart = () => {
  return (
    <Box width={803} height={300}>
      <ApexCharts
        width={803}
        height={320}
        type="bar"
        series={[
          {
            name: "평균 만족도",
            data: [2.4, 3.2, 4.2, 3, 3.2, 2.3, 4.9, 4.2, 4.5, 3.9, 4.1],
          },
        ]}
        options={{
          xaxis: {
            categories: [
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
            ],
            labels: {
              style: {
                fontSize: "15px",
                colors: [
                  "#969696",
                  "#969696",
                  "#969696",
                  "#969696",
                  "#969696",
                  "#969696",
                  "#969696",
                  "#969696",
                  "#969696",
                  "#969696",
                  "#969696",
                  "#969696",
                ],
              },
            },
          },
          grid: {
            strokeDashArray: 3,
            position: "back",
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            colors: ["#0A929B"],
          },
          tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              return (
                '<div class="box">' +
                '<span class = "goods">평균 만족도</span>' +
                '<span class="scores">' +
                series[seriesIndex][dataPointIndex] +
                "★" +
                "</span>" +
                "</div>"
              );
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "20%",
            },
          },
          yaxis: {
            labels: {
              show: true,
              formatter(val, opts) {
                if (val === 0) {
                  return "";
                } else {
                  return "★" + Math.ceil(val);
                }
              },
              style: {
                fontSize: "15px",
                fontWeight: 400,
                colors: ["#969696"],
              },
              offsetX: -10,
              offsetY: 5,
            },
            tickAmount: 5,
            max: 5,
            min: 0,
          },
        }}
      />
    </Box>
  );
};
export default MyBarChart;
