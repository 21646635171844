import styled from "styled-components";

const Layout = styled.div`
  display: flex;
  background-color: #fff;
  width: ${(props) => props.width || 650}px;
  height: ${(props) => props.height || 480} px;
  font-family: "Roboto", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */
  color: #313131;
  flex-direction: column;
  border-radius: 10px;
  padding: 25px;
`;
const TextBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  align-self: flex-start;
`;

const Date = styled.div`
  align-self: flex-end;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */
  text-align: center;

  color: #969696;
`;
const Chart = styled.div`
  padding-top: 20px;
`;

const Container = (props) => {
  return (
    <Layout width={props.width} height={props.height}>
      <TextBox>
        <Title>{props.title}</Title>
        <Date>{props.date}</Date>
      </TextBox>
      <Chart>{props.chart}</Chart>
    </Layout>
  );
};
export default Container;
