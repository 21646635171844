import ApexCharts, { LineChart } from "react-apexcharts";
import Box from "../../component/Box";
import "./styled.css";
const GradientLineChart = () => {
  const series = [
    {
      name: "Monthly Visitors",
      data: [
        20000, 201420, 200300, 178502, 176423, 310482, 327321, 342531, 401295,
        360942, 358401,
      ],
      fill: {
        color: "#0A929B",
      },
    },
  ];

  const options = {
    grid: {
      strokeDashArray: 3,
      position: "back",
    },
    dataLabels: {
      enabled: false,
    },
    chart: {
      type: "line",
      background: "#fff",
      foreColor: "#0A929B",
    },
    xaxis: {
      labels: {
        style: {
          fontSize: "15px",
          colors: [
            "#969696",
            "#969696",
            "#969696",
            "#969696",
            "#969696",
            "#969696",
            "#969696",
            "#969696",
            "#969696",
            "#969696",
            "#969696",
            "#969696",
          ],
        },
      },
      categories: [
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
      ],
    },
    stroke: {
      width: [4],
      curve: "straight",
      colors: ["#0A929B"],
    },
    colors: ["#0A929B"],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#0A929B"],
        shadeIntensity: 1,
        type: "vertical",
        opacityFrom: 0.5,
        opacityTo: 0.1,
        stops: [0, 100, 100, 100],
      },
    },
    markers: {
      size: 4,
      colors: ["#fff"],
      strokeColor: "#0A929B",
      strokeWidth: 2,
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        let mung = series[seriesIndex][dataPointIndex];
        return (
          '<div class="arrow">' +
          '<span class = "go">총 관람객 수</span>' +
          '<span class="sco">' +
          mung.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") +
          "명" +
          "</span>" +
          "</div>"
        );
      },
    },
    yaxis: {
      labels: {
        show: true,
        formatter(val, opts) {
          if (val === 0) {
            return "";
          } else {
            return val / 10000 + "만";
          }
        },
        style: {
          fontSize: "15px",
          fontWeight: 400,
          colors: ["#969696"],
        },
        offsetX: -10,
        offsetY: 5,
      },
      tickAmount: 6,
      max: 600000,
      min: 0,
    },
  };

  return (
    <Box>
      <ApexCharts
        options={options}
        series={series}
        type={"area"}
        color={"#ff0000"}
        width={840}
        height={300}
      />
    </Box>
  );
};

export default GradientLineChart;
