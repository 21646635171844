import ApexCharts from "react-apexcharts";
import Box from "../../component/Box";
import "./styled.css";

const StackedBarChart = () => {
  const series = [
    {
      name: "Man",
      data: [2000, 2300, 3200, 2200, 3100, 800],
    },
    {
      name: "Woman",
      data: [1900, 700, 2000, 2000, 2000, 1400],
    },
  ];

  const options = {
    chart: {
      stacked: true,
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "25%",
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: ["0-12", "13-19", "20-30", "30-40", "40-50", "50~"],
      labels: {
        style: {
          fontSize: "15px",
          colors: [
            "#969696",
            "#969696",
            "#969696",
            "#969696",
            "#969696",
            "#969696",
          ],
        },
      },
    },
    colors: ["#3854B7", "#FE718E"],
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      labels: {
        show: true,
        formatter(val, opts) {
          if (val === 0) {
            return "";
          } else {
            return val / 1000 + "천";
          }
        },
        style: {
          fontSize: "15px",
          fontWeight: 400,
          colors: ["#969696"],
        },
        offsetX: -10,
        offsetY: 5,
      },
    },
    grid: {
      strokeDashArray: 3,
      position: "back",
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        let value = w.globals.labels[dataPointIndex];
        let mung = series[0][dataPointIndex] + series[1][dataPointIndex];
        return (
          '<div class="arrow_box">' +
          '<span class = "good">' +
          value +
          " 관람객 수</span>" +
          '<span class="score">' +
          mung.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") +
          "명" +
          "</span>" +
          '<span class = "man">(남: ' +
          series[0][dataPointIndex] +
          "명, 여:" +
          series[1][dataPointIndex] +
          "명)</span>" +
          "</div>"
        );
      },
    },
  };

  return (
    <Box>
      <ApexCharts
        options={options}
        series={series}
        type={"bar"}
        height="355px"
        width="332px"
      />
    </Box>
  );
};

export default StackedBarChart;
