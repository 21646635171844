import styled from "styled-components";
import Search from "../../images/Frame 1000000827.svg";
import User from "../../images/Group 1000001537.svg";
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 1400px;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 15px;
  border-bottom: 2px solid #edeff2;
`;

const Title = styled.div`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 167% */

  /* Grey Blue 50 */
  color: #4d5e80;
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 430px;
`;
const ImgContainer = styled.img``;

const TopBar = () => {
  return (
    <Wrapper>
      <Title>인천 공연 및 행사 통합관리시스템(임시)</Title>
      <InfoContainer>
        <ImgContainer src={Search} />
        <ImgContainer src={User} />
      </InfoContainer>
    </Wrapper>
  );
};

export default TopBar;
