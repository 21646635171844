import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "../pages";
import MyBarChart from "../components/BarChart";

import AgeGroup from "../components/AgeChart";
import GradientLineChart from "../components/LineChart";
import DoubleLineChart from "../components/DoubleLineChart";
import Second from "../pages/Second";

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/second" element={<Second />} />
      <Route path="/bar" element={<MyBarChart />} />
      <Route path="/circle" element={<AgeGroup />} />
      <Route path="/line" element={<GradientLineChart />} />
      <Route path="/path" element={<DoubleLineChart />} />
    </Routes>
  </BrowserRouter>
);
export default Router;
