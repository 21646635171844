import styled from "styled-components";
import { useState, useEffect } from "react";
import hand from "../images/hand.svg";
import medal from "../images/medal.svg";
import star from "../images/star.svg";
import ing from "../images/ing.svg";
import downloadExcel from "../images/downloadExcel.svg";
import axios from "axios";
import Pusher from "pusher-js";

const Wrapper = styled.div`
  font-family: Roboto;
  width: 450px;
  background-color: #fff;
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
  margin: 30px;
  column-gap: 10px;
`;
const TitleImg = styled.img``;
const TitleDiv = styled.div`
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
`;
const PosterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 30px;
`;
const PosterImg = styled.img`
  width: 150px;
  height: 210px;
  margin-right: 20px;
`;
const InfoTitle = styled.div`
  color: #969696;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`;
const InfoData = styled.div`
  color: #181818;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;
const InfoTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
`;
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;
const RealTimeTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px;
  margin-top: 75px;
`;
const RealTimeText = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #313131;
`;
const RealTimeTime = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 30px;
  color: #969696;
`;
const InfoBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px;
  height: 120px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.11);
`;
const InfoBoxTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const InfoBoxTitle = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
`;
const InfoBoxText = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 28px;
`;
const InfoBoxImage = styled.img`
  width: 66px;
  height: 66px;
`;
const DownloadButtonWrapper = styled.div`
  margin: 30px;
  margin-top: 160px;
  display: flex;
  justify-content: flex-end;
`;
const DownloadButton = styled.img`
  cursor: pointer;
`;

const convertTime = (time) => {
  return `${time.getFullYear()}.${(time.getMonth() + 1)
    .toString()
    .padStart(2, "0")}.${time.getDay().toString().padStart(2, "0")} ${time
    .getHours()
    .toString()
    .padStart(2, "0")}:${time.getMinutes().toString().padStart(2, "0")}:${time
    .getSeconds()
    .toString()
    .padStart(2, "0")}`;
};
const LeftBar = () => {
  const [time, setTime] = useState(new Date(0));
  const [count, setCount] = useState(0);
  const [rating, setRating] = useState(0);
  const [ratingCount, setRatingCount] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 300);
    axios.get("https://sinjid-api.b-cdn.net/data").then((res) => {
      setCount(res.data.count);
      setRating(res.data.rate);
      setRatingCount(res.data.rate_count);
    });
    const pusher = new Pusher("287533acc6962a5ca31b", {
      cluster: "ap3",
    });
    const channel = pusher.subscribe("my-channel");
    channel.bind("my-event", function (data) {
      setCount(data.count);
      setRating(data.rate);
      setRatingCount(data.rate_count);
    });

    return () => clearInterval(interval);
  }, []);

  return (
    <Wrapper>
      <TitleWrapper>
        <TitleDiv>댕댕런 2022 인천</TitleDiv>
        <TitleImg src={ing} />
      </TitleWrapper>
      <PosterWrapper>
        <PosterImg src={"/ddr_poster.png"} />
        <InfoWrapper>
          <InfoTextWrapper>
            <InfoTitle>개요</InfoTitle>
            <InfoData>레포츠</InfoData>
          </InfoTextWrapper>
          <InfoTextWrapper>
            <InfoTitle>기간</InfoTitle>
            <InfoData>2022.09.24 ~ 2022.09.24</InfoData>
          </InfoTextWrapper>
          <InfoTextWrapper>
            <InfoTitle>시간</InfoTitle>
            <InfoData>08:00 ~ 24:00</InfoData>
          </InfoTextWrapper>
          <InfoTextWrapper>
            <InfoTitle>장소</InfoTitle>
            <InfoData>인천 송도 달빛축제공원</InfoData>
          </InfoTextWrapper>
          <InfoTextWrapper>
            <InfoTitle>주최</InfoTitle>
            <InfoData>
              (주)1986프로덕션,
              <br />
              (주)보듬컴퍼니
            </InfoData>
          </InfoTextWrapper>
          <InfoTextWrapper>
            <InfoTitle>주관</InfoTitle>
            <InfoData>42</InfoData>
          </InfoTextWrapper>
        </InfoWrapper>
      </PosterWrapper>
      <RealTimeTextWrapper>
        <RealTimeText>실시간 정보</RealTimeText>
        <RealTimeTime>{convertTime(time)}</RealTimeTime>
      </RealTimeTextWrapper>
      <InfoBox>
        <InfoBoxTextWrapper>
          <InfoBoxTitle>참가자 현황</InfoBoxTitle>
          <InfoBoxText>{count}명</InfoBoxText>
        </InfoBoxTextWrapper>
        <InfoBoxImage src={hand} />
      </InfoBox>
      <InfoBox>
        <InfoBoxTextWrapper>
          <InfoBoxTitle>실시간 평균 별점</InfoBoxTitle>
          <InfoBoxText>
            {rating}★ <span style={{ fontSize: 16 }}>({ratingCount}명)</span>
          </InfoBoxText>
        </InfoBoxTextWrapper>
        <InfoBoxImage src={star} />
      </InfoBox>
      <InfoBox>
        <InfoBoxTextWrapper>
          <InfoBoxTitle>실시간 공연 및 행사 순위</InfoBoxTitle>
          <InfoBoxText>3위</InfoBoxText>
        </InfoBoxTextWrapper>
        <InfoBoxImage src={medal} />
      </InfoBox>
      <DownloadButtonWrapper>
        <DownloadButton src={downloadExcel} />
      </DownloadButtonWrapper>
    </Wrapper>
  );
};

export default LeftBar;
